<template>
    <nav id="sidebar">
                <ul class="list-unstyled menu-categories" id="accordionExample">
                    <li class="menu">
                        <router-link :to="{name: 'home'}" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-home"></i>
                                <span>Tableau de bord</span>
                            </div>
                        </router-link>
                    </li>
                    <li class="menu">
                        <router-link
                        :to="{name: 'users'}"
                        aria-expanded="false"
                        class="dropdown-toggle"
                        >
                        <div class="">
                            <i class="las la-user" />
                            <span>Utilisateur</span>
                        </div>
                        </router-link>
                    </li>
                    <li class="menu-title">Points de vente</li>
                    <li class="menu">
                        <a href="#host" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-lock"></i>
                                <span>Sites</span>
                            </div>
                            <div>
                                <!-- <i class="las la-angle-right sidemenu-right-icon"></i> -->
                                <span class="menu-badge badge-danger">New</span>
                            </div>
                        </a>
                        <ul class="collapse submenu list-unstyled" id="host" data-parent="#accordionExample">
                            <li>
                                <router-link :to="{name: 'hosts'}"> List  </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'hosts_map'}"> Map <span class="menu-badge badge-danger">New</span> </router-link>
                            </li>
                            
                        </ul>
                    </li>
                    <!-- <li class="menu">
                        <a href="#pages" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-file"></i>
                                <span>Commande</span>
                            </div>
                            <div>
                                <i class="las la-angle-right sidemenu-right-icon"></i>
                            </div>
                        </a>
                        <ul class="collapse submenu list-unstyled" id="pages" data-parent="#accordionExample">
                            <li>
                                <a href="pages_coming_soon.html"> Coming Soon </a>
                            </li>
                        </ul>
                    </li> -->
                    <li class="menu-title">Administration</li>
                    <li class="menu">
                        <a href="#config_sys" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-drafting-compass"></i>
                                <span>Materiels</span>
                            </div>
                            <div>
                                <i class="las la-angle-right sidemenu-right-icon"></i>
                            </div>
                        </a>
                        <ul class="collapse submenu list-unstyled" id="config_sys" data-parent="#accordionExample">
                            <li>
                                <router-link :to="{name: 'model' }"> Modèle  </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'version' }"> Version </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'firmware' }"> Firmware </router-link>
                            </li>
                        </ul>
                    </li>
                    
                    <li class="menu-title">Others</li>
                    <li class="menu">
                        <a href="#" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-file-code"></i>
                                <span>Documentation</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>