export const PROD_URL = 'backend.cashwater.net'
export const BASE_URL = (process.env.NODE_ENV === 'production')
? PROD_URL
: 'localhost:3001'

export const GRAPHQL_API_ROOT = (process.env.NODE_ENV === 'production')
  ? 'https://'+ BASE_URL + '/graphql'
  : (process.env.NODE_ENV === 'staging')
  ? 'https://'+ BASE_URL + '/graphql'
  : 'http://' + BASE_URL +'/graphql'

export const WS_ROOT =  (process.env.NODE_ENV === 'production')
? 'wss://'+ BASE_URL + '/graphql'
: (process.env.NODE_ENV === 'test')
? 'wss://'+ BASE_URL + '/graphql'
: 'ws://' + BASE_URL +'/graphql'

export const API_VERSION = '1.0.0'
export const ID_TOKEN_KEY = 'app_token'
export const PERPAGE_KEY = 'dt_perpage'
export const DEFAULT_LANGUAGE = 'fr'
export const ADD = 'ADD'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'

export const TYPE_ADMIN = 'admin'
export const TYPE_SUPER_ADMIN = 'super_admin'
export const TYPE_PARTENAIRE = 'partner' 
export const TYPE_ONG = 'ong'

