import jQuery from 'jquery'
import PerfectScrollbar from 'perfect-scrollbar';
window.jQuery = jQuery
const $ = jQuery
if(window.innerWidth <= 1068){
    $('.responsive-msg-component').hide();
}
// // document.addEventListener('contextmenu', event => event.preventDefault());
// $(document).keydown(function (event) {
//     if (event.keyCode == 123) {
//        return false;
//     }
//     if (event.ctrlKey && (event.keyCode === 85 || event.keyCode === 83 || event.keyCode ===65 )) {
//        return false;
//     }
//     else if (event.ctrlKey && event.shiftKey && event.keyCode === 73)
//     {
//        return false;
//     }
// });
// $(document).ready(function(){
//     $('.bs-tooltip').click(function () {
//        $('.bs-tooltip').tooltip("hide");
//     });
//     $('.close-msg-component').click(function () {
//         $('.responsive-msg-component').hide();
//     });
// });
// var App = function() {
const App = {
    MediaSize :{
        xl: 1200,
        lg: 992,
        md: 991,
        sm: 576
    },
    ToggleClasses : {
        headerhamburger: '.toggle-sidebar',
        inputFocused: 'input-focused',
    },
    Selector: {
        mainHeader: '.header.navbar',
        headerhamburger: '.toggle-sidebar',
        fixed: '.fixed-top',
        mainContainer: '.main-container',
        sidebar: '#sidebar',
        sidebarContent: '#sidebar-content',
        sidebarStickyContent: '.sticky-sidebar-content',
        ariaExpandedTrue: '#sidebar [aria-expanded="true"]',
        ariaExpandedFalse: '#sidebar [aria-expanded="false"]',
        contentWrapper: '#content',
        contentWrapperContent: '.container',
        mainContentArea: '.main-content',
        searchFull: '.toggle-search',
        rightBar:'.right-bar',
        overlay: {
            sidebar: '.overlay',
            cs: '.cs-overlay',
            search: '.search-overlay'
        }
    },
    toggleFunction : {
        sidebar: function() {
            $('.sidebarCollapse').on('click', function (sidebar) {
                sidebar.preventDefault();
                $(App.Selector.mainContainer).toggleClass("sidebar-closed");
                $(App.Selector.mainHeader).toggleClass('expand-header');
                $(App.Selector.mainContainer).toggleClass("sbar-open");
                $('.overlay').toggleClass('show');
                $('html,body').toggleClass('sidebar-noneoverflow');
            });
        },
        overlay: function() {
            $('#dismiss, .overlay, cs-overlay').on('click', function () {
                // hide sidebar
                $(App.Selector.mainContainer).addClass('sidebar-closed');
                $(App.Selector.mainContainer).removeClass('sbar-open');
                // hide overlay
                $('.overlay').removeClass('show');
                $('html,body').removeClass('sidebar-noneoverflow');
            });
        },
        search: function() {
            $(App.Selector.searchFull).click( () =>  {
               $(this).parents('.search-animated').find('.search-full').addClass(App.ToggleClasses.inputFocused);
               $(this).parents('.search-animated').addClass('show-search');
               $(App.Selector.overlay.search).addClass('show');
               $(App.Selector.overlay.search).addClass('show');
            });
            $(App.Selector.overlay.search).click( () => {
               $(this).removeClass('show');
               $(App.Selector.searchFull).parents('.search-animated').find('.search-full').removeClass(App.ToggleClasses.inputFocused);
               $(App.Selector.searchFull).parents('.search-animated').removeClass('show-search');
            });
        },
        rightbar: function() {
            $('.rightbarCollapse').on('click', function () {
                $('.rightbar-overlay').toggleClass('show');               
                $('body').toggleClass('right-bar-enabled');
            });
        },
        rightbarClose: function() {
            $('.rightbar-overlay').on('click', function () {
                $('.rightbar-overlay').removeClass('show');               
                $('body').removeClass('right-bar-enabled');
                // Open first tab in right bar everytime
                $('.right-bar .simplebar-content .nav-tabs .nav-item:nth-child(3) a.nav-link').removeClass('active');
                $('.right-bar .simplebar-content .nav-tabs .nav-item:nth-child(2) a.nav-link').removeClass('active');
                $('.right-bar .simplebar-content .nav-tabs .nav-item:nth-child(1) a.nav-link').addClass('active');
                $('.right-bar .simplebar-content .tab-content .tab-pane:nth-child(3)').removeClass('active');
                $('.right-bar .simplebar-content .tab-content .tab-pane:nth-child(2)').removeClass('active');
                $('.right-bar .simplebar-content .tab-content .tab-pane:nth-child(1)').addClass('active');
            });
        },
    },
    inBuiltfunctionality : {
        mainCatActivateScroll: function() {
            const ps = new PerfectScrollbar('.menu-categories', {
                wheelSpeed:.5,
                swipeEasing:!0,
                minScrollbarLength:40,
                maxScrollbarLength:300,
                suppressScrollX : true
            });
            console.log(ps)
            // const vs = new PerfectScrollbar('.rightbar-tab', {
            //     wheelSpeed:.5,
            //     swipeEasing:!0,
            //     minScrollbarLength:40,
            //     maxScrollbarLength:300,
            //     suppressScrollX : true
            // });
            // console.log(vs)
        },
        preventScrollBody: function() {
            $('#sidebar').bind('mousewheel DOMMouseScroll', function(e) {
                var scrollTo = null;
                if (e.type == 'mousewheel') {
                    scrollTo = (e.originalEvent.wheelDelta * -1);
                }
                else if (e.type == 'DOMMouseScroll') {
                    scrollTo = 40 * e.originalEvent.detail;
                }
                if (scrollTo) {
                    e.preventDefault();
                    $(this).scrollTop(scrollTo + $(this).scrollTop());
                }
            });
            $('.rightbar-tab-container').bind('mousewheel DOMMouseScroll', function(e) {
                var scrollTo = null;
                if (e.type == 'mousewheel') {
                    scrollTo = (e.originalEvent.wheelDelta * -1);
                }
                else if (e.type == 'DOMMouseScroll') {
                    scrollTo = 40 * e.originalEvent.detail;
                }
                if (scrollTo) {
                    e.preventDefault();
                    $(this).scrollTop(scrollTo + $(this).scrollTop());
                }
            });
        },
        functionalDropdown: function() {
            var getDropdownElement = document.querySelectorAll('.more-dropdown .dropdown-item');
            for (var i = 0; i < getDropdownElement.length; i++) {
                getDropdownElement[i].addEventListener('click', function() {
                    document.querySelectorAll('.more-dropdown .dropdown-toggle > span')[0].innerText = this.getAttribute('data-value');
                })
            }
        }
    },
    fullScreenMode :{
        fullscreen: function() {
            var toggle; 
            $('.full-screen-mode').on('click', function () {
                toggle = !toggle;
                var myId = document.getElementById('fullScreenIcon');
                if(toggle){
                    myId.classList.remove("la-compress");
                    myId.classList.add("la-compress-arrows-alt");
                    var elem = document.documentElement;
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.mozRequestFullScreen) { /* Firefox */
                        elem.mozRequestFullScreen();
                    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) { /* IE/Edge */
                        elem.msRequestFullscreen();
                    }
                } else if(!toggle) {
                    myId.classList.remove("la-compress-arrows-alt");
                    myId.classList.add("la-compress");
                    document.getElementById("fullScreenIcon").classList.remove('helo');
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    } 
                }
            })
        },
    },
    _mobileResolution : {
        onRefresh: function() {
            var windowWidth = window.innerWidth;
            if ( windowWidth <= App.MediaSize.md ) {
                App.toggleFunction.sidebar();
            }
        },
    },
    _desktopResolution : {
        onRefresh: function() {
            var windowWidth = window.innerWidth;
            if ( windowWidth > App.MediaSize.md ) {
                App.toggleFunction.sidebar(true);
            }
        },
    },
    sidebarFunctionality : {
        sidebarCloser :  () => {
            if (window.innerWidth <= 991 ) {
                if (!$('body').hasClass('alt-menu')) {
                    $("#container").addClass("sidebar-closed");
                    $('.overlay').removeClass('show');
                } else {
                    $(".navbar").removeClass("expand-header");
                    $('.overlay').removeClass('show');
                    $('#container').removeClass('sbar-open');
                    $('html, body').removeClass('sidebar-noneoverflow');
                }
            } else if (window.innerWidth > 991 ) {
                if (!$('body').hasClass('alt-menu')) {
                    $("#container").removeClass("sidebar-closed");
                    $(".navbar").removeClass("expand-header");
                    $('.overlay').removeClass('show');
                    $('#container').removeClass('sbar-open');
                    $('html, body').removeClass('sidebar-noneoverflow');
                } else {
                    $('html, body').addClass('sidebar-noneoverflow');
                    $("#container").addClass("sidebar-closed");
                    $(".navbar").addClass("expand-header");
                    $('.overlay').addClass('show');
                    $('#container').addClass('sbar-open');
                }
            }
        },
        sidebarMobCheck : () => {
            if (window.innerWidth <= 991 ) {
                if ( $('.main-container').hasClass('sbar-open') ) {
                    return;
                } else {
                    App.sidebarFunctionality.sidebarCloser()
                }
            } else if (window.innerWidth > 991 ) {
                App.sidebarFunctionality.sidebarCloser();
            }
        },
        Callbacks : () =>  {
            App.sidebarFunctionality.sidebarCloser();
            $(window).resize(function(event) {
                console.log(event)
                App.sidebarFunctionality.sidebarMobCheck();
            });
        },
        
        // FOR RIPPLE EFFECT
        // (function($, window, document, undefined) {
        //     'use strict';
        //     var $ripple = $('.js-ripple');
        //     $ripple.on('click.ui.ripple', function(e) {
        //       var $this = $(this);
        //       var $offset = $this.parent().offset();
        //       var $circle = $this.find('.ripple-ripple__circle');
        //       var x = e.pageX - $offset.left;
        //       var y = e.pageY - $offset.top;
        //       $circle.css({
        //         top: y + 'px',
        //         left: x + 'px'
        //       });
        //       $this.addClass('is-active');
        //     });
        //     $ripple.on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', function(e) {
        //         $(this).removeClass('is-active');
        //     });
        // })(jQuery, window, document);
    },
    
    init : () => {
        App.toggleFunction.overlay();
        App.toggleFunction.search();
        App.toggleFunction.rightbar();
        App.toggleFunction.rightbarClose();
        // Full Screen Mode
        App.fullScreenMode.fullscreen();
        /*
            Desktop Resoltion fn
        */
        App._desktopResolution.onRefresh();
        // Note : -  _desktopResolution -> onResize | Uncomment it if need for onresize functions for MOBILE RESOLUTION i.e. above or equal to 991px |
        // App._desktopResolution.onResize();
        /*
            Mobile Resoltion fn
        */
        App._mobileResolution.onRefresh();
        // Note : -  _mobileResolution -> onResize | Uncomment it if need for onresize functions for DESKTOP RESOLUTION i.e. below or equal to 991px |
        // _mobileResolution.onResize();
        App.sidebarFunctionality.Callbacks();
        App.inBuiltfunctionality.mainCatActivateScroll();
        App.inBuiltfunctionality.preventScrollBody();
        App.inBuiltfunctionality.functionalDropdown();
    }
}

export {App}
