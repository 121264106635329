
const state = {
  models: [],
  versions: [],
  firmwares: [],
  hosts: [],
  clients: [],
  historiques: [],
  recharges: [],
  towerData: []
}

const getters = {
  models: state => state.models,
  versions: state => state.versions,
  firmwares: state => state.firmwares,
  hosts: state => state.hosts,
  clients: state => state.clients,
  recharges: state => state.recharges,
  historiques: state => state.historiques,
  towerData: state => state.towerData
}

const actions = {
    
}

const mutations = {
  //MODELES
  SET_MODELS: (state, models) => {
    state.models = models
  },
  ADD_MODEL: (state, model) => {
    state.models.push(model)
  },
  UPDATE_MODEL: (state, model) => {
    state.models = state.models.filter(item => item.uid !== model.uid)
    state.models.push(model)
  },
  DELETE_MODEL: (state, model) => {
    state.models = state.models.filter(item => item.uid !== model.uid)
  },

  // VERSIONS
  SET_VERSIONS: (state, versions) => {
    state.versions = versions
  },
  ADD_VERSION: (state, version) => {
    state.versions.push(version)
  },
  UPDATE_VERSION: (state, version) => {
    state.versions = state.versions.filter(item => item.uid !== version.uid)
    state.versions.push(version)
  },
  DELETE_VERSION: (state, version) => {
    state.versions = state.versions.filter(item => item.uid !== version.uid)
  },

  // FIRMWARE
  SET_FIRMWARES: (state, firmwares) => {
    state.firmwares = firmwares
  },
  ADD_FIRMWARE: (state, firmware) => {
    state.firmwares.push(firmware)
  },
  UPDATE_FIRMWARE: (state, firmware) => {
    state.versions = state.versions.filter(item => item.uid !== firmware.uid)
    state.firmwares.push(firmware)
  },
  DELETE_FIRMWARE: (state, firmware) => {
    state.versions = state.versions.filter(item => item.uid !== firmware.uid)
  },

  // HOSTS
  SET_HOSTS: (state, hosts) => {
    state.hosts = hosts
  },

  ADD_HOST: (state, host) => {
    state.hosts.push(host)
  },
  UPDATE_HOST: (state, host) => {
    state.hosts = state.hosts.filter(item => item.uid !== host.uid)
    state.hosts.push(host)
  },
  DELETE_HOST: (state, host)=> {
    state.hosts = state.hosts.filter(item => item.uid !== host.uid)
  },
  
  // CLIENTS
  SET_CLIENTS: (state, clients) => {
    state.clients = clients
  },
  ADD_CLIENT: (state, client) => {
    state.clients.push(client)
  },
  UPDATE_CLIENT: (state, client) => {
    state.clients = state.clients.filter(item => item.uid !== client.uid)
    state.clients.push(client)
  },
  DELETE_CLIENT: (state, client) => {
    state.clients = state.clients.filter(item => item.uid !== client.uid)
  },

  //HISTORIQUES
  SET_HISTORIQUES: (state, historiques) => {
    state.historiques = historiques
  },
  ADD_HISTORIQUE: (state, historique) => {
    state.historiques.push(historique)
  },
  UPDATE_HISTORIQUE: (state, historique) => {
    state.historiques = state.historiques.filter(item => item.uid !== historique.uid) 
    state.historiques.push(historique)
  },
  DELETE_HISTORIQUE: (state, historique) => {
    state.historiques = state.historiques.filter(item => item.uid !== historique.uid) 
  },

  // RECHARGES
  SET_RECHARGES: (state, recharges) => {
    state.recharges = recharges
  },
  ADD_RECHARGE: (state, recharge) => {
    state.recharges.push(recharge)
  },
  UPDATE_RECHARGE: (state, recharge) => {
    state.recharges = state.recharges.filter(item => item.uid !== recharge.uid)
    state.recharges.push(recharge)
  },
  DELETE_RECHARGE: (state, recharge) => {
    state.recharges = state.recharges.filter(item => item.uid !== recharge.uid)
  },

  SET_TOWER_DATA: (state, list) => {
    state.towerData = list
  },

  ADD_TOWER_DATA: (state, d) => {
    state.towerData.push(d)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
