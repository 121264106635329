<template>
  <div id="app">
    <router-view v-if="!logged"/>
    <layout v-if="logged && !isClient"/>
    <client-layout v-if="logged && isClient"/>
  </div>
</template>
<script>
import Layout from './components/template/layout.vue'
import clientLayout from './components/template/clientLayout.vue'
import {isLogin} from './plugins/authService'
import { mapGetters } from 'vuex'
export default {
  components: { Layout, clientLayout },
  data(){
    return {
      logged: isLogin()
    }
  },
  computed: {
    ...mapGetters({
      isClient: 'auth/is_client'
    })
  }
}
</script>

<style>

</style>
