import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import host from './modules/host'
import options from './modules/options'
const debug = process.env.NODE_ENV !== 'production'
Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    done: 0
  },
  getters: {
    done: state => state.done
  },
  mutations: {
    DONE: (state) => {
      state.done = state.done + 1
    }
  },
  actions: {
  },
  modules: {
    auth,
    host,
    options
  },
  strict: debug
})
