import gql from 'graphql-tag'

export const CLIENT_INIT_DATA = gql`
query Query {
    me {
        uid
        login
        fullname
        telephone
        pays
        typeCompte
        address
        lastConnexion
        enable
        firstConnexion
    }
    historiques {
        client
        badge
        volume
        date
        host
        uid
    }
    recharges {
        client
        host
        prix
        volume
        date
        uid
    }
    hosts {
        numero
        imei
        long
        lat
        key
        nbClient
        owner
        version
        firmware
        model
        lastActivity
        uid
        createdAt
        options
    }
}
`



export const INIT_DATA = gql`
    query Query {
        me {
            uid
            login
            fullname
            telephone
            pays
            typeCompte
            address
            lastConnexion
            enable
            firstConnexion
        }
        users {
            uid
            login
            fullname
            telephone
            pays
            typeCompte
            address
            lastConnexion
            enable
            firstConnexion
            createBy
        }
        typeComptes {
            uid
            libelle
            code
        }
        pays {
            uid
            libelle
            code
        }
        configPrix {
            uid
            volume
            prix
        }
        models {
            uid
            name
            description
            hasClient
        }
        versions {
            uid
            libelle
            description
            name
            model
            active
            deprecat
            recommandedVersion
            dateInvalid
        }
        firmwares {
            uid
            libelle
            description
            version
            active
            deprecat
            dateInvalid
            recommanded
        }
        hosts {
            numero
            imei
            long
            lat
            key
            nbClient
            owner
            version
            firmware
            model
            lastActivity
            uid
            createdAt
            options
            hasTowerController
            hauteur
            volume
        }
        clients {
            fullname
            telephone
            volume
            host
            uid
            rootId
            lastActivity
            badges {
                code
                state
                uid
                client
            }
        }
        historiques {
            client
            badge
            volume
            date
            host
            uid
        }
        recharges {
            client
            host
            prix
            volume
            date
            uid
        }
        towerData {
            uid
            rootId
            host
            niveau
            volume
            date
          }
    }
`