
const load  = (component) => {
    // '@' is aliased to src/components
    return () => import(`../views/${component}.vue`)
  }
  
export default [
    {
        path: '/',
        name: 'home',
        component: load('Home')
    },
    {
        path: '/notification',
        name: 'notification',
        component: load('Notification')
    },
    {
        path: '/login',
        name: 'login',
        component: load('Login')
    },
    {
        path: '/users',
        name: 'users',
        component: load('User/Index')
    },
    {
        path: '/add-user',
        name: 'add_user',
        component: load('User/Add')
    },
    {
        path: '/version',
        name: 'version',
        component: load('Host/Version')
    },
    {
        path: '/firmware',
        name: 'firmware',
        component: load('Host/Firmware')
    },
    {
        path: '/model',
        name: 'model',
        component: load('Host/Model')
    },
    {
        path: '/hosts',
        name: 'hosts',
        component: load('Host/Index')
    },
    {
        path: '/hosts-map',
        name: 'hosts_map',
        component: load('Host/Map')
    },
    {
        path: '/host/:uid',
        name: 'host',
        component: load('Host/HostDetail')
    },
    {
        path: '/add-host',
        name: 'add_host',
        component: load('Host/Add')
    },

    {
        path: '/historique-client',
        name: 'historique_client',
        component: load('Client/Historique')
    },
    {
        path: '/recharche-client',
        name: 'recharge_client',
        component: load('Client/Recharge')
    },
    {
        // Redirect utilisateur.js to supported lang version.
        path: '*',
        name: 'NotFound',
        // component: NotFound
    
      }
]