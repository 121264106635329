import gql from 'graphql-tag'

/**
 * VERSION
 */
export const ADD_VERSION = gql`
  mutation Mutation($version: VersionInput!) {
  addVersion(version: $version)
}
`
export const DELETE_VERSION = gql`
    mutation Mutation($uid: String!) {
        deleteVersion(uid: $uid)
    }
`
export const SUBSCRIBE_VERSION = gql`
    subscription Subscription {
        version {
            type
            data {
            uid
            libelle
            description
            name
            model
            active
            deprecat
            recommandedVersion
            dateInvalid
          }
        }
    }
`

/**
 * FIRMWARE
 */
export const ADD_FIRMWARE = gql`
mutation AddModel($firmware: FirmwareInput!) {
  addFirmware(firmware: $firmware)
}
`
export const SUBCRIBE_FIRMWARE= gql`
subscription Subscription {
  firmware {
    type
    data {
      uid
      libelle
      description
      version
      active
      deprecat
      dateInvalid
      recommanded
    }
  }
}
`

/**
 * Modele
 */

export const ADD_MODEL = gql`
mutation AddModel($model: ModelInput) {
  addModel(model: $model)
}
`
export const DELETE_MODEL = gql`
    mutation DeleteModelMutation($uid: String!) {
        deleteModel(uid: $uid)
    }
`
export const SUBSCRIBE_MODEL = gql`
    subscription Subscription {
        model {
            type
            data {
            uid
            name
            description
            uid
            hasClient
            }
        }
    }
`

/**
 * HOST
 */

export const ADD_HOST = gql`
mutation AddHost($host: HostInput) {
  addHost(host: $host)
}
`
export const DELETE_HOST = gql`
mutation AddHost($uid: String!) {
  deleteHost(uid: $uid)
}
`

export const SUBSCRIBE_HOST = gql`
subscription Subscription($uid: String!) {
  host(uid: $uid) {
    type
    data {
      numero
      imei
      long
      lat
      key
      nbClient
      owner
      supervisor
      version
      firmware
      lastActivity
      uid
      createdAt
      options
      hasTowerController
      hauteur
      volume
    }
  }
}
`


/**
 * CLIENT
 */
export const SUBSCRIBE_CLIENT = gql`
subscription Subscription($uid: String!) {
  client(uid: $uid) {
    type
    data {
        fullname
        telephone
        volume
        host
        uid
        rootId
        lastActivity
        badges {
            code
            state
            uid
            client
        }
    }
  }
}
`

/**
 * RECHARGE
 */
export const SUBSCRIBE_RECHARGE = gql`
subscription Subscription($uid: String!) {
  recharge(uid: $uid) {
    type
    data {
        client
        host
        prix
        volume
        date
        uid
    }
  }
}
`

/**
 * HISTORIQUE
 */

export const SUBSCRIBE_HISTORIQUE = gql`
subscription Subscription($uid: String!) {
  historique(uid: $uid) {
    type
    data {
        client
        badge
        volume
        date
        host
        uid
    }
  }
}
`