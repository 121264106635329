<template>
    <nav id="sidebar">
                <ul class="list-unstyled menu-categories" id="accordionExample">
                    <li class="menu">
                        <router-link :to="{name: 'home'}" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-home"></i>
                                <span>Tableau de bord</span>
                            </div>
                        </router-link>
                    </li>
                    <li class="menu">
                        <router-link :to="{name: 'historique_client'}" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-history"></i>
                                <span>Historique activités</span>
                            </div>
                        </router-link>
                    </li>
                    <li class="menu">
                        <router-link :to="{name: 'recharge_client'}" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-wallet"></i>
                                <span>Recharge</span>
                            </div>
                        </router-link>
                    </li>
                    <li class="menu">
                        <router-link :to="{name: 'hosts_map'}" aria-expanded="false" class="dropdown-toggle">
                            <div class="">
                                <i class="las la-map"></i>
                                <span>Points de vente</span>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </nav>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>