import moment from 'moment'
 import { DEFAULT_LANGUAGE } from '../constants/app'
const translateSexe = {
  fr: {
    m: 'Mâle',
    f: 'Femelle'
  },
  en: {
    m: 'Male',
    f: 'Female'
  }
}

moment.locale(DEFAULT_LANGUAGE)

export const showTime = value => {
  if (value === null) { return '-' }
  let d = moment(value)
  let now = moment()
  if(now.format('X') - d.format('X')> 3600)  return d.format('llll:ss')
  return d.fromNow()

}

export const humanReadableTime = value => {
  if (value === null) { return '-' }
  return moment.unix(value).fromNow()
}

export const capitalize = value => {
  if (typeof value !== 'string') { return 'undefine' }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const systemReadableTime = value => {
  if (value === null) { return '-' }
  return moment.unix(value).format('L')
}

export const systemDateTime = value => {
  if (value === null) { return '-' }
  return moment.unix(value).format('llll')
}

export const truncate = (value, length) => {
  return value.substring(0, length)
}

export const libelle = (objet = {}) => {
  if (objet === null) { return '-' }
  if (objet[DEFAULT_LANGUAGE] !== undefined) { return objet[DEFAULT_LANGUAGE] }
  return 'Error in Objet'
}

export const sexe = (placeholder) => {
  if (placeholder !== 'f' && placeholder !== 'm') return 'error sexe'
  return translateSexe[DEFAULT_LANGUAGE][placeholder]
}
