import { login, sigup, signOut } from '../../plugins/authService'
import { TYPE_ADMIN, TYPE_PARTENAIRE, TYPE_SUPER_ADMIN } from '../../constants/app'
import {jwtDecode } from 'jwt-decode';

const state = {
  me: null,
  token: login() || null,
  connected: true,
  typeComptes: [],
  pays: [],
  users: []
}

const getters = {
  me: state => state.me,
  token: state => state.token,

  connected: state => state.connected,
  typeComptes: state => state.typeComptes,
  pays: state => state.pays,
  typeUtilisateur: state => state.typeComptes.find(item => item.uid === state.me.typeCompte),
  is_client: state => { 
    let d = jwtDecode(state.token)
    return d.t_user === 'client' 
  },
  is_partenaire: state => {
    let type = state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_PARTENAIRE
    return false
  },
  is_admin: state => {
    let type = state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_ADMIN || type.code === TYPE_SUPER_ADMIN
    return false
  },
  is_super_admin: state => {
    let type = state.typeComptes.find(item => item.uid === state.me.typeCompte)
    if(type !== undefined) return type.code === TYPE_SUPER_ADMIN
    return false
  },
  users: state => state.users
}

const actions = {
    
}

const mutations = {
  SET_ME: (state, me) => {
    state.me = me
  },
  LOGIN_USER: (state, token) =>{
    state.token = token,
    sigup(token)
  },
  LOGOUT_USER: (state) => {
    state.me = null,
    state.token = null,
    signOut()
  },
  SET_CONNECTED: (state, etat) => {
    state.connected = etat
  },
  SET_TYPE_COMPTES: (state, typecomptes) => {
      state.typeComptes = typecomptes
  },
  SET_PAYS: (state, pays) => {
    state.pays = pays
  },
  SET_USERS: (state, users) => {
      state.users = users
  },
  ADD_USER: (state, user) => {
      state.users.push(user)
  },
  UPDATE_USER: (state, user) => {
      state.users = state.users.filter(item => item.uid !== user.uid)
      state.users.push(user)
  },
  DELETE_USER: (state, user) => {
    state.users = state.users.filter(item => item.uid !== user.uid)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
