<template>
    <div>
        <!--  Navbar Starts  -->
        <div class="header-container fixed-top">
            <theme-header/>
        </div>
        <!--  Navbar Ends  -->
        <!--  Main Container Starts  -->
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <div class="rightbar-overlay"></div>
            <!--  Sidebar Starts  -->
            <div class="sidebar-wrapper sidebar-theme">
                <theme-menu/>
            </div>
            <!--  Sidebar Ends  -->
            <!--  Content Area Starts  -->
            <div id="content" class="main-content">
                <!-- Main Body Starts -->
                <div class="layout-px-spacing">
                    <router-view/>
                </div>
                <!-- Main Body Ends -->
                <div class="responsive-msg-component">
                    <p>
                        <a class="close-msg-component"><i class="las la-times"></i></a>
                        Please reload the page to view the responsive functionalities
                    </p>
                </div>
                <!-- Copyright Footer Starts -->
                <div class="footer-wrapper">
                    <div class="footer-section f-section-1">
                        <p class="">Copyright © 2021 <a target="_blank" href="#">FORTECK Sarl</a>, All rights reserved.</p>
                    </div>
                    <div class="footer-section f-section-2">
                        <p class="">Crafted with extra <i class="las la-heart text-danger"></i></p>
                    </div>
                </div>
                <!-- Copyright Footer Ends -->
                <!-- Arrow Starts -->
                <div class="scroll-top-arrow" style="display: none;">
                    <i class="las la-angle-up"></i>
                </div>
                <!-- Arrow Ends -->
            </div>
        </div>
    </div>
    
</template>
<script>
import {  mapMutations } from 'vuex'
import ThemeHeader from './themeHeader.vue'
import ThemeMenu from './themeMenu.vue'
import { App } from '../../assets/js/app'
import { INIT_DATA } from '../../graphql'
import {SUBSCRIBE_HOST, SUBSCRIBE_MODEL, SUBSCRIBE_VERSION, SUBSCRIBE_CLIENT, SUBSCRIBE_RECHARGE, SUBSCRIBE_HISTORIQUE, SUBCRIBE_FIRMWARE} from '../../graphql/host'
import {ADD, UPDATE, DELETE} from '../../constants/app'
export default {
    components: { ThemeHeader, ThemeMenu },
    mounted(){
        App.init()
    },
    data(){
        return {
            me: null
        }
    },
    apollo: {
        init: {
            query: INIT_DATA,
            update (data) {
                console.log(data)
                this.me = data.me
                this.setMe(data.me)
                this.setUsers(data.users)
                this.setModels(data.models)
                this.setTypeComptes(data.typeComptes)
                this.setPays(data.pays)
                this.setVersions(data.versions)
                this.setFirmwares(data.firmwares)
                this.setHosts(data.hosts)
                this.setClients(data.clients)
                this.setHistoriques(data.historiques)
                this.setRecharges(data.recharges)
                this.setTowerData(data.towerData)
            }
        },
        $subscribe: {
            model: {
                query: SUBSCRIBE_MODEL,
                result({data}){
                    let model = data.model
                    console.log(model)
                    if(model.type === ADD) this.addModel(model.data)
                    if(model.type === UPDATE) this.updateModel(model.data)
                    if(model.type === DELETE) this.deleteModel(model.data)
                }
            },
            version: {
                query: SUBSCRIBE_VERSION,
                result({data}){
                    let version = data.version
                    if(version.type === ADD) this.addVersion(version.data)
                    if(version.type === UPDATE) this.updateVersion(version.data)
                    if(version.type === DELETE) this.deleteVersion(version.data)
                }
            },
            firmware: {
                query: SUBCRIBE_FIRMWARE,
                result({data}) {
                    let firmware = data.firmware
                    if(firmware.type === ADD) this.addFirmware(firmware.data)
                    if(firmware.type === UPDATE) this.updateFirmware(firmware.data)
                    if(firmware.type === DELETE) this.deleteFirmware(firmware.data)
                }
            },
            host: {
                query: SUBSCRIBE_HOST,
                variables () {
                    return {
                        uid: this.me.uid
                    }
                },
                skip () {
                    return this.me === null
                },
                result({data}){
                    let host = data.host
                    if(host.type === ADD) this.addHost(host.data)
                    if(host.type === UPDATE) this.updateHost(host.data)
                    if(host.type === DELETE) this.deleteHost(host.data)
                }
            },
            client: {
                query: SUBSCRIBE_CLIENT,
                variables () {
                    return {
                        uid: this.me.uid
                    }
                },
                skip () {
                    return this.me === null
                },
                result({data}){
                    let client = data.client
                    if(client.type === ADD) this.addClient(client.data)
                    if(client.type === UPDATE) this.updateClient(client.data)
                    if(client.type === DELETE) this.deleteClient(client.data)
                }
            },
            historique: {
                query: SUBSCRIBE_HISTORIQUE,
                variables () {
                    return {
                        uid: this.me.uid
                    }
                },
                skip () {
                    return this.me === null
                },
                result({data}){
                    let historique = data.historique
                    if(historique.type === ADD) this.addHistorique(historique.data)
                    if(historique.type === UPDATE) this.updateHistorique(historique.data)
                    if(historique.type === DELETE) this.deleteHistorique(historique.data)
                }
            },
            recharge: {
                query: SUBSCRIBE_RECHARGE,
                variables () {
                    return {
                        uid: this.me.uid
                    }
                },
                skip () {
                    return this.me === null
                },
                result({data}){
                    let recharge = data.recharge
                    if(recharge.type === ADD) this.addRecharge(recharge.data)
                    if(recharge.type === UPDATE) this.updateRecharge(recharge.data)
                    if(recharge.type === DELETE) this.deleteRecharge(recharge.data)
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            setMe: 'auth/SET_ME',
            setUsers: 'auth/SET_USERS',
            setModels: 'host/SET_MODELS',
            addModel: 'host/ADD_MODEL',
            updateModel: 'host/UPDATE_MODEL',
            deleteModel: 'host/DELETE_MODEL',
            setVersions: 'host/SET_VERSIONS',
            
            setTypeComptes: 'auth/SET_TYPE_COMPTES',
            setPays: 'auth/SET_PAYS',
            addVersion: 'host/ADD_VERSION',
            updateVersion: 'host/UPDATE_VERSION',
            deleteVersion: 'host/DELETE_VERSION',

            setFirmwares: 'host/SET_FIRMWARES',
            addFirmware: 'host/ADD_FIRMWARE',
            updateFirmware: 'host/UPDATE_FIRMWARE',
            deleteFirmware: 'host/DELETE_FIRMWARE',

            // Host
            setHosts: 'host/SET_HOSTS',
            updateHost: 'host/UPDATE_HOST',
            addHost: 'host/ADD_HOST',
            deleteHost: 'host/DELETE_HOST',

            // Client
            setClients: 'host/SET_CLIENTS',
            addClient: 'host/ADD_CLIENT',
            updateClient: 'host/UPDATE_CLIENT',
            deleteClient: 'host/DELETE_CLIENT',

            // Historique
            setHistoriques: 'host/SET_HISTORIQUES',
            addHistorique: 'host/ADD_HISTORIQUE',
            updateHitorique: 'host/UPDATE_HISTORIQUE',
            deleteHistorique: 'host/DELETE_HISTORIQUE',

            // Recharge
            setRecharges: 'host/SET_RECHARGES',
            addRecharge: 'host/ADD_RECHARGE',
            updateRecharge: 'host/UPDATE_RECHARGE',
            deleteRecharge: 'host/DELETE_RECHARGE',

            // TowerData
            setTowerData: 'host/SET_TOWER_DATA',
            addTowerData: 'host/ADD_TOWER_DATA'
        })
    },
    
}
</script>
<style lang="scss" scoped>

</style>