import Vue from 'vue'
import {ApolloClient} from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

import VueApollo from 'vue-apollo'

import {GRAPHQL_API_ROOT, WS_ROOT} from '../constants/app'
import { login } from '../plugins/authService'

Vue.use(VueApollo)

const getHeaders = () => {
    const headers = {}
    const token = login()
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    return headers
  }

const httpLink = new HttpLink({
    uri: GRAPHQL_API_ROOT,
    fetch,
    headers: getHeaders()
})

const wsLink = new WebSocketLink({
  uri: WS_ROOT,
  options: {
    reconnect: true
  }
})

const link = split(
    ({query}) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    },
    wsLink,
    httpLink
)
const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  })
  
  export default apolloProvider
  // Manually call this when user log in
//   export async function onLogin (apolloClient, token) {
//     if (typeof localStorage !== 'undefined' && token) {
//       localStorage.setItem(AUTH_TOKEN, token)
//     }
//     if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//     try {
//       await apolloClient.resetStore()
//     } catch (e) {
//       // eslint-disable-next-line no-console
//       console.log('%cError on cache reset (login)', 'color: orange;', e.message)
//     }
//   }
  
  // Manually call this when user log out
//   export async function onLogout (apolloClient) {
//     if (typeof localStorage !== 'undefined') {
//       localStorage.removeItem(AUTH_TOKEN)
//     }
//     if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//     try {
//       await apolloClient.resetStore()
//     } catch (e) {
//       // eslint-disable-next-line no-console
//       console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
//     }
//   }
  
